<template>
    <teleport to="#modal">
        <transition leave-active-class="duration-200">
            <div
                v-show="show"
                class="fixed overflow-hidden z-modal inset-0 max-h-screen p-6 md:p-4 flex flex-col justify-center items-center">
                <transition
                    enter-active-class="ease-out duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="ease-in duration-200"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0">
                    <div
                        v-show="show"
                        class="fixed inset-0 transform transition-all"
                        @click="close">
                        <div class="absolute inset-0 bg-gray-200 opacity-75"></div>
                    </div>
                </transition>

                <transition
                    enter-active-class="ease-out duration-300"
                    enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="ease-in duration-200"
                    leave-class="opacity-100 translate-y-0 sm:scale-100"
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        v-show="show"
                        class="bg-white rounded shadow-xl transform transition-all p-6 relative"
                        :class="{
                            'w-full md:w-2/3 mx-auto max-w-md max-h-full': size === 'small',
                            'w-full md:w-11/12 h-11/12 mx-auto': size === 'big',
                             'w-full md:w-11/12 h-auto mx-auto': size === 'big-fluid'
                        }">
                        <div class="absolute right-0 top-0 -m-4">
                            <SmallCircleButton
                                icon="cross"
                                @click.prevent="close"
                                v-if="closeable" />
                        </div>
                        <simplebar
                            class="max-h-full"
                            v-if="scrollable">
                            <slot></slot>
                        </simplebar>
                        <slot v-else></slot>
                    </div>
                </transition>
            </div>
        </transition>
    </teleport>
</template>

<script>
import Simplebar from "components/globals/Simplebar"
import SmallCircleButton from "components/globals/SmallCircleButton"

export default {
    name: "modal",
    props: {
        scrollable: {
            default: true,
            type: Boolean
        },
        show: {
            default: false,
            type: Boolean
        },
        size: {
            default: "small",
            type: String
        },
        closeable: {
            default: true,
            type: Boolean
        }
    },
    components: {
        SmallCircleButton,
        Simplebar
    },
    emits: ["close"],

    methods: {
        close() {
            if (this.closeable) {
                this.$emit("close")
            }
        },
        closeOnEscape(e) {
            if (e.key === "Escape" && this.show) {
                this.close()
            }
        }
    },

    watch: {
        show: {
            immediate: true,
            handler: function (show) {
                if (show) {
                    document.body.style.overflow = "hidden"
                } else {
                    document.body.style.overflow = null
                }
            }
        }
    },
    created() {
        document.addEventListener("keydown", this.closeOnEscape)
    },

    beforeUnmount() {
        document.removeEventListener("keydown", this.closeOnEscape)
        document.body.style.overflow = null
    },

    computed: {}
}
</script>

<template>
    <modal
        v-if="showModal"
        :show="showModal"
        size="big-fluid"
        @close="closeModal">
        <h2 class="mt-6 text-center px-6">
            {{ $t("modal.tutorialVideo.title") }}
        </h2>
        <p class="text-center text-purple -small md:mx-12 mt-2">
            {{ $t("modal.tutorialVideo.description") }}
        </p>
        <video
            preload="none"
            controls
            controlslist="nodownload"
            :poster="coverImagePng"
            @click="$refs.video.play()"
            ref="video"
            class="block cursor-pointer border-4 rounded border-white mt-3 w-full h-full mx-auto"
            src="https://adimo.app/assets/videos/premier-pas-parents.mp4?ver=1.0.0" />
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import coverImagePng from "assets/images/cover-premier-pas.png"
import * as types from "store/types"

export default {
    name: "TutorialVideoModal",
    components: { Modal },
    emits: ["close"],
    data() {
        return {
            coverImagePng,
            showModal: false
        }
    },
    watch: {
        "$store.getters.isUserLogin"() {
            this.$nextTick(() => {
                this.checkIfWeNeedToShowModal()
            })
        }
    },
    created() {
        this.checkIfWeNeedToShowModal();
    },
    methods: {
        checkIfWeNeedToShowModal() {
            if (!this.$store.getters.isUserLogin) {
                return
            }

            if (!this.$store.state.auth.user.isParent) {
                return
            }

            //if has seen tutorial video, don't show it again
            if (this.$store.state.auth.user.settings && this.$store.state.auth.user.settings.hasSeenTutorialVideo) {
                return
            }

            this.$store.dispatch(types.UPDATE_USER, {
               settings: {
                   hasSeenTutorialVideo: true
               }
            })

            this.showModal = true
        },
        closeModal() {
            this.showModal = false
        }
    }
}
</script>
